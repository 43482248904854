import tw from 'twin.macro';
import { Link } from 'gatsby';
import {
  SiReact,
  SiGatsby,
  SiTailwindcss,
  SiChakraui,
  SiWordpress,
  SiSentry,
  SiNodedotjs,
  SiGraphql,
  SiMongodb,
  SiApollographql,
  SiStripe,
  SiKubernetes,
  SiAmazonaws,
  SiDocker,
} from 'react-icons/si';

export const Title = tw.h2`font-display text-3xl font-bold mb-4 tracking-wide `;

export const SubTitle = tw.h3`mt-12 block w-full font-bold text-lg md:text-xl leading-tight text-center mb-2 sm:px-4 md:px-28 tracking-wide sm:text-2xl`;

export const AdvantageContent = tw.p`text-gray-700 text-lg md:text-xl mb-4 justify-center`;

export const ReactIcon = tw(SiReact)`mx-auto w-16 h-16 mb-1 text-[#00D1F7]`;

export const GatsbyIcon = tw(SiGatsby)`mx-auto w-16 h-16 mb-1 text-[#704A99]`;

export const TailwindIcon = tw(SiTailwindcss)`mx-auto w-16 h-16 mb-1 text-[#36B7F0]`;

export const ChakraIcon = tw(SiChakraui)`mx-auto w-16 h-16 mb-1 text-[#29B9AD]`;

export const WordPressIcon = tw(SiWordpress)`mx-auto w-16 h-16 mb-1 text-[#207196]`;

export const SentryIcon = tw(SiSentry)`mx-auto w-16 h-16 mb-1 text-[#3D2247]`;

export const NodeIcon = tw(SiNodedotjs)`mx-auto w-16 h-16 mb-1 text-[#4C993B]`;

export const GraphqlIcon = tw(SiGraphql)`mx-auto w-16 h-16 mb-1 text-[#DA0093]`;

export const MongodbIcon = tw(SiMongodb)`mx-auto w-16 h-16 mb-1 text-[#50AE3F]`;

export const ApolloIcon = tw(SiApollographql)`mx-auto w-16 h-16 mb-1 text-[#000000]`;

export const StripeIcon = tw(SiStripe)`mx-auto w-16 h-16 mb-1 text-[#6358F7]`;

export const KubernetesIcon = tw(SiKubernetes)`mx-auto w-16 h-16 mb-1 text-[#3069DE] `;

export const AwsIcon = tw(SiAmazonaws)`mx-auto w-16 h-16 mb-1 text-[#F79400]`;

export const DockerIcon = tw(SiDocker)`mx-auto w-16 h-16 mb-1 text-[#2391E6]`;

export const JobsContainer = tw.section`w-full bg-white py-8 md:py-16 mx-auto`;

export const JobTitle = tw.h2`font-display w-full px-8 md:px-8 mb-8 md:mb-12 text-center text-2xl lg:text-4xl font-bold leading-tight text-gray-700`;
