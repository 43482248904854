import React from 'react';
import { graphql } from 'gatsby';
import useMedia from 'use-media';
import tw from 'twin.macro';

import Advantage, { AdvantageRow } from '../components/Advantage';
import { Badges } from '../components/Badges/';
import { Hero } from '../components/Hero/';
import { ImageTextRow } from '../components/ImageTextRow/';
import PageLayout from '../components/PageLayout/';
import Section from '../components/Section/';
import SEO from '../components/seo';
import {
  Title,
  AdvantageContent,
  SubTitle,
  ReactIcon,
  GatsbyIcon,
  TailwindIcon,
  ChakraIcon,
  SentryIcon,
  NodeIcon,
  GraphqlIcon,
  MongodbIcon,
  ApolloIcon,
  StripeIcon,
  KubernetesIcon,
  AwsIcon,
  DockerIcon,
  JobsContainer,
  JobTitle,
} from '../styles/junior-bootcamp';

const BootCampPage = ({
  data: { HeroImage, BootCamp1, BootCamp2, BootCamp3, BootCamp4, BootCamp5 },
}) => {
  const isMobile = useMedia({ maxWidth: '767px' });

  return (
    <PageLayout>
      <SEO title="DropFriends Bootcamp für Junior EntwicklerInnen " />
      <Hero
        image={HeroImage}
        title="Mache den nächsten Schritt in Deiner Entwicklerkarriere!"
        subTitle="Mit dem DropFriends-Bootcamp."
        fullscreen>
        <Badges />
      </Hero>

      <Section fullWidth>
        <ImageTextRow rightImageFluid={BootCamp1} position="right" fullWidth id="BootCamp1">
          <Title>Was erwartet Dich?</Title>
          <AdvantageContent>- Bootcamp meets Festanstellung</AdvantageContent>
          <AdvantageContent>- Echte Projekte und wichtige Arbeitserfahrung</AdvantageContent>
          <AdvantageContent>
            - Praxisorientierte Trainings und persönliche Weiterbildung
          </AdvantageContent>
          <AdvantageContent>- Deine erste Developeranstellung im Lebenslauf</AdvantageContent>
        </ImageTextRow>
        <ImageTextRow leftImageFluid={BootCamp2} position="left" fullWidth>
          <Title>Auf vertrautem Boden</Title>
          <AdvantageContent>
            Die Voraussetzung für das DropFriends-Bootcamp: Du hast bereits ein erstes Bootcamp
            absolviert? Herzlichen Glückwunsch! Jetzt ist es wichtig, nicht locker zu lassen und
            Dein neues Wissen weiter auszubauen!
          </AdvantageContent>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={BootCamp3} position="right" fullWidth>
          <Title>Gemeinsam zum Erfolg </Title>
          <AdvantageContent>
            Im DropFriends Bootcamp bieten wir Dir als angestellter Junior Developer neben einem
            Gehalt praxisorientierte Trainings durch Tutoren innerhalb des Gründer-Teams. Unser
            gemeinsames Ziel: Werde ein Developer Professional und lege Deinen Junior Titel ab.
          </AdvantageContent>
        </ImageTextRow>
        <ImageTextRow leftImageFluid={BootCamp4} position="left" fullWidth>
          <Title>Echte Projekte & Arbeitserfahrungen </Title>
          <AdvantageContent>
            Arbeite direkt an echten Projekten und sammle wichtige Arbeitserfahrung, ohne dabei
            etwas bezahlen zu müssen. Durch die lange Erfahrung der Tutoren erarbeitest Du Dir viele
            neue Kenntnisse und Fähigkeiten und bist so bestens für die Zukunft gerüstet.
          </AdvantageContent>
        </ImageTextRow>

        <ImageTextRow rightImageFluid={BootCamp5} position="right" fullWidth>
          <Title>Dein nächster Karriereschritt</Title>
          <AdvantageContent>
            Das DropFriends-Bootcamp im Zusammenspiel mit Deiner Tätigkeit bei DropFriends ist der
            nächste Schritt in Deiner Entwicklerkarriere und wertet Deinen Lebenslauf und Dein
            Projekt-Portfolio deutlich auf.
          </AdvantageContent>
        </ImageTextRow>
      </Section>

      <AdvantageRow
        title="DropFriends Tech Stack"
        titleId="app"
        tw="justify-center mt-16 py-0 pb-0">
        <SubTitle className="mt-4">Frontend Development </SubTitle>
        <Advantage icon={<ReactIcon />} title="React / React Native" />
        <Advantage icon={<GatsbyIcon />} title="Gatsby" />
        <Advantage icon={<TailwindIcon />} title="Tailwind CSS" />
        <Advantage icon={<ChakraIcon />} title="Chakra UI" />
        <Advantage icon={<ReactIcon />} title="Formik" />
        {/* <Advantage icon={<WordPressIcon />} title="WordPress" /> */}
        <Advantage icon={<SentryIcon />} title="Sentry" />

        <SubTitle>Backend Development</SubTitle>
        <Advantage icon={<NodeIcon />} title="NodeJS" />
        <Advantage icon={<GraphqlIcon />} title="GraphQL" />
        <Advantage icon={<MongodbIcon />} title="MongoDB" />
        <Advantage icon={<ApolloIcon />} title="Apollo Server" />
        <Advantage icon={<StripeIcon />} title="Stripe" />

        <SubTitle>DevOps </SubTitle>
        <Advantage icon={<KubernetesIcon />} title="Kubernetes" />
        <Advantage icon={<AwsIcon />} title="AWS" />
        <Advantage icon={<DockerIcon />} title="Docker" />
      </AdvantageRow>

      {/* <JobsContainer>
        <div className=" mx-auto">
          <JobTitle>Unsere Stellenangebote</JobTitle>
          <iframe
            title="Jobs bei DropFriends"
            src="https://www.catch-talents.de/api/embed.php?id=SGFsbG8gV2VsdCEgMjIx&embed=1"
            frameBorder="0"
            height={isMobile ? '1300px' : '900px'}
            width="100%"
          />
        </div>
      </JobsContainer> */}
    </PageLayout>
  );
};

export const query = graphql`
  {
    HeroImage: file(relativePath: { eq: "bootcamp/jb-boy-orange-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, layout: FULL_WIDTH)
      }
    }
    BootCamp1: file(relativePath: { eq: "bootcamp/jb-boy-aqua-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    BootCamp2: file(relativePath: { eq: "bootcamp/jb-lady-lila-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, layout: FULL_WIDTH)
      }
    }
    BootCamp3: file(relativePath: { eq: "bootcamp/jb-lady-cyan-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    BootCamp4: file(relativePath: { eq: "bootcamp/jb-boy-yellow-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    BootCamp5: file(relativePath: { eq: "bootcamp/jb-lady-rose-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default BootCampPage;
